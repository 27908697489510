const WordsAroundMic = ({color}) => {
  return (
    // <div className='words-around-mic'>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className='words-around-mic'
        width="523.000000pt" height="455.000000pt" viewBox="0 0 523.000000 455.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,455.000000) scale(0.100000,-0.100000)"
          fill={color} stroke="none">
          <path d="M2819 3861 c-112 -37 -181 -131 -196 -270 -14 -136 61 -250 192 -290
101 -31 206 -29 276 7 95 48 149 148 149 277 0 175 -109 273 -310 281 -47 2
-97 0 -111 -5z m165 -145 c47 -20 66 -51 66 -111 0 -142 -72 -214 -173 -171
-51 21 -71 69 -62 145 16 124 78 175 169 137z"/>
          <path d="M2185 3793 c-84 -19 -183 -79 -219 -134 -53 -79 -52 -177 3 -294 37
-79 71 -118 126 -144 125 -59 342 12 404 132 26 51 31 129 12 166 -14 25 -37
22 -111 -13 -37 -17 -46 -26 -48 -51 -6 -66 -48 -105 -113 -105 -42 0 -90 51
-112 120 -30 91 -20 137 33 165 44 23 76 18 113 -15 19 -16 42 -30 50 -30 20
0 118 38 131 50 27 27 -64 131 -131 149 -38 11 -100 13 -138 4z"/>
          <path d="M3503 3638 c-26 -35 -91 -117 -143 -183 -112 -141 -140 -179 -140
-194 0 -10 109 -101 121 -101 3 0 39 39 79 87 41 47 75 82 77 77 2 -6 -15 -54
-37 -109 -22 -54 -40 -104 -40 -111 0 -17 88 -84 110 -84 10 0 58 29 106 65
126 93 129 88 30 -45 -31 -41 -56 -80 -56 -85 0 -11 99 -91 126 -102 10 -4 22
1 33 14 106 134 292 374 306 396 l18 28 -38 32 c-84 70 -138 107 -156 107 -11
0 -67 -36 -124 -81 -116 -89 -135 -89 -97 -1 11 26 34 79 51 119 17 39 28 77
25 85 -6 16 -178 148 -193 148 -5 0 -31 -28 -58 -62z"/>
          <path d="M1222 3017 c-67 -111 -122 -205 -122 -209 0 -4 80 -55 178 -114 252
-152 294 -175 308 -170 19 7 244 391 244 415 0 10 -90 61 -108 61 -4 0 -41
-54 -81 -120 -40 -67 -79 -120 -87 -120 -23 0 -54 19 -54 32 0 7 25 54 55 104
30 51 55 99 55 108 0 15 -76 66 -98 66 -6 0 -25 -26 -43 -57 -66 -118 -88
-144 -117 -141 -15 2 -28 10 -30 18 -1 8 29 69 68 135 38 66 69 124 67 130 -3
9 -94 64 -108 65 -3 0 -60 -91 -127 -203z"/>
          <path d="M4175 2979 c-44 -16 -152 -55 -240 -85 -88 -31 -164 -60 -169 -65
-12 -11 18 -109 42 -138 l17 -21 90 30 c50 17 97 29 105 27 9 -2 -25 -32 -79
-72 -103 -76 -105 -79 -67 -168 l15 -38 101 2 c142 2 147 -11 24 -57 -42 -16
-79 -35 -81 -42 -3 -6 5 -41 18 -77 21 -61 22 -71 11 -136 -14 -79 -15 -129
-4 -129 4 0 17 -8 30 -18 22 -17 23 -20 11 -92 -14 -92 -18 -100 -60 -114 -37
-12 -51 -43 -64 -138 -5 -36 -18 -61 -60 -115 -30 -37 -55 -71 -55 -75 0 -3
38 -37 85 -74 47 -37 85 -73 85 -80 0 -17 -34 -22 -190 -29 l-125 -5 -43 -47
c-53 -60 -60 -81 -34 -102 130 -104 373 -292 396 -306 l29 -19 34 39 c19 22
40 52 48 67 15 27 14 28 -43 75 -32 26 -73 60 -92 74 -19 15 -31 31 -27 35 4
5 78 12 163 16 95 4 161 12 170 19 29 24 86 110 81 122 -4 12 -287 235 -373
296 -43 30 -43 49 -1 55 17 3 97 17 177 31 80 13 188 32 240 41 52 9 102 20
111 25 11 6 21 32 28 76 7 38 14 82 17 100 5 28 2 34 -33 56 -99 62 -421 247
-431 247 -16 0 -15 30 1 34 33 8 168 55 315 108 128 48 162 64 162 78 0 10
-16 64 -36 120 l-37 101 -138 -7 c-116 -6 -139 -4 -139 7 0 8 44 44 97 80 53
36 101 73 107 81 7 12 2 39 -19 100 -38 111 -52 138 -73 138 -9 -1 -53 -14
-97 -31z m55 -1108 c25 -15 44 -32 43 -37 -3 -11 -107 -28 -128 -20 -8 3 -15
16 -15 30 0 63 28 71 100 27z"/>
          <path d="M1140 2501 c-116 -37 -176 -134 -174 -286 1 -61 6 -91 24 -128 27
-58 87 -111 148 -131 54 -19 188 -21 244 -4 127 38 204 211 164 369 -21 81
-36 103 -94 140 -34 21 -57 29 -82 27 l-35 -3 -9 -69 c-9 -63 -8 -71 9 -80 51
-29 75 -64 75 -108 0 -56 -21 -85 -72 -98 -72 -20 -195 8 -217 48 -15 28 -13
93 2 113 7 9 30 25 52 35 33 16 41 26 48 59 4 22 7 58 5 80 -3 37 -6 40 -33
42 -16 1 -41 -2 -55 -6z"/>
          <path d="M1415 1815 c-60 -18 -168 -50 -240 -70 -71 -21 -136 -43 -143 -50
-21 -19 24 -165 50 -165 26 0 510 140 522 151 5 4 -2 41 -15 86 -19 61 -29 78
-44 80 -11 1 -69 -14 -130 -32z"/>
          <path d="M1493 1496 c-61 -20 -103 -49 -161 -110 -92 -97 -102 -200 -32 -322
94 -165 245 -228 383 -161 72 36 146 107 176 170 46 95 12 223 -87 329 -82 88
-188 124 -279 94z m176 -191 c61 -52 61 -117 -2 -183 -85 -90 -189 -94 -233
-11 -33 64 -5 130 80 187 61 41 111 43 155 7z"/>
          <path d="M3325 1055 c-209 -95 -271 -161 -269 -285 1 -113 78 -246 168 -292
85 -43 190 -22 397 80 58 29 73 41 71 56 -3 18 -91 216 -182 414 -21 45 -45
82 -52 82 -7 -1 -67 -25 -133 -55z m82 -252 c24 -53 47 -112 50 -130 5 -30 3
-34 -30 -48 -73 -30 -112 -13 -151 64 -28 55 -41 111 -31 140 8 25 72 71 98
71 13 0 31 -26 64 -97z"/>
          <path d="M2011 908 c-234 -245 -261 -274 -261 -287 0 -10 135 -81 153 -81 4 0
203 221 224 249 6 9 14 13 18 10 3 -4 -2 -84 -11 -178 -11 -104 -14 -175 -8
-180 20 -19 157 -72 164 -64 9 9 50 455 50 539 l0 51 -90 42 c-50 22 -93 41
-97 41 -3 0 -67 -64 -142 -142z"/>
        </g>
      </svg>
    // </div>
  )
}

export default WordsAroundMic
