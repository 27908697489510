import React from "react";
import SignUp from "../../components/SignUp";
// import { useAuth, } from "../../redux/pagesSlices/authSlice";

export default function Signup() {
  // useAuth();
  return (
    <div>
      <SignUp />
    </div>
  );
}
